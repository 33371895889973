// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  dashboard: '/dashboard',
};

// ----------------------------------------------------------------------

// eslint-disable-next-line import/prefer-default-export
export const PATHS = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
  },
  dashboard: {
    reportOverview: `${ROOTS.dashboard}/report-overview`,
    root: ROOTS.dashboard,
    homepage: {
      root: path(ROOTS.dashboard, '/homepage'),
      createBannerHomepage: path(ROOTS.dashboard, '/homepage/create-banner'),
      detailBannerHomepage: path(
        ROOTS.dashboard,
        '/homepage/detail-banner/:bannerId',
      ),
    },
    disruptors: {
      root: path(ROOTS.dashboard, '/disruptors'),
      detail: path(ROOTS.dashboard, '/disruptors/:disruptorId'),
      createDisruptors: path(ROOTS.dashboard, '/disruptors/create-chapter'),
    },
    advertiseWithUs: {
      root: path(ROOTS.dashboard, '/advertise-with-us'),
    },
    articles: {
      root: `${ROOTS.dashboard}/articles`,
      createArticles: `${ROOTS.dashboard}/articles/create-articles`,
    },
    adsPlacement: {
      root: `${ROOTS.dashboard}/ads-placement`,
      homepageDesktop: `${ROOTS.dashboard}/ads-placement/homepage/desktop`,
      homepageMobile: `${ROOTS.dashboard}/ads-placement/homepage/mobile`,
      articleCategoryDesktop: `${ROOTS.dashboard}/ads-placement/article-category/desktop`,
      articleCategoryMobile: `${ROOTS.dashboard}/ads-placement/article-category/mobile`,
      articleDetailDesktop: `${ROOTS.dashboard}/ads-placement/article-detail/desktop`,
      articleDetailMobile: `${ROOTS.dashboard}/ads-placement/article-detail/mobile`,
      videoDesktop: `${ROOTS.dashboard}/ads-placement/video/desktop`,
      videoMobile: `${ROOTS.dashboard}/ads-placement/video/mobile`,
      videoPlaylistDetailDesktop: `${ROOTS.dashboard}/ads-placement/video-playlist-detail/desktop`,
      videoPlaylistDetailMobile: `${ROOTS.dashboard}/ads-placement/video-playlist-detail/mobile`,
      podcastDesktop: `${ROOTS.dashboard}/ads-placement/podcast/desktop`,
      podcastMobile: `${ROOTS.dashboard}/ads-placement/podcast/mobile`,
      topicsDesktop: `${ROOTS.dashboard}/ads-placement/topics/desktop`,
      topicsMobile: `${ROOTS.dashboard}/ads-placement/topics/mobile`,
      adsSummary: `${ROOTS.dashboard}/ads-placement/summary`,
      adsSummaryDetail: `${ROOTS.dashboard}/ads-placement/summary/:positionId`,
    },
    podcast: {
      root: `${ROOTS.dashboard}/podcast`,
    },
    video: {
      root: `${ROOTS.dashboard}/video`,
      playlistItem: `${ROOTS.dashboard}/video/:playlistSlug`,
    },
    newVideos: {
      root: `${ROOTS.dashboard}/newVideos`,
    },
    user: {
      root: `${ROOTS.dashboard}/user`,
    },
    client: {
      root: `${ROOTS.dashboard}/client`,
    },
    analytic: {
      root: `${ROOTS.dashboard}/analytic`,
      clients: `${ROOTS.dashboard}/analytic/clients`,
      ads: `${ROOTS.dashboard}/analytic/clients/:clientId`,
      articles: `${ROOTS.dashboard}/analytic/articles`,
      report: `${ROOTS.dashboard}/analytic/clients/report-overview/:adsId`,
    },
    notification: {
      root: `${ROOTS.dashboard}/notification`,
    },
    community: {
      root: `${ROOTS.dashboard}/community`,
    },
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  notFound: '/not-found',
  error: '/error',
};
