import { Navigate, useRoutes } from 'react-router-dom';
import React from 'react';
import useAuth from 'src/hooks/useAuth';
import LoadingScreen from 'src/components/LoadingScreen';
import PropTypes from 'prop-types';
import { PATHS } from './paths';
import loader from './loader';
import DashboardLayout from '../layouts/dashboard';

// ----------------------------------------------------------------------
const RequireAuth = ({ children, isLoading, isAuthenticated }) => {
  if (isLoading) {
    return <LoadingScreen />;
  }
  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }
  return children;
};

export default function Router() {
  const { isLoading, isAuthenticated } = useAuth();

  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard" />,
    },
    // Auth Routes
    {
      path: PATHS.auth.login,
      element: isAuthenticated ? (
        <Navigate to="/" />
      ) : (
        loader(() => import('../views/auth/LoginView'))
      ),
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <RequireAuth isLoading={isLoading} isAuthenticated={isAuthenticated}>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [
        {
          path: '/dashboard',
          element: loader(() => import('../views/dashboard/DashboardView')),
        },
        {
          path: PATHS.dashboard.homepage.root,
          element: loader(() => import('../views/dashboard/HomepageView')),
        },
        {
          path: PATHS.dashboard.homepage.createBannerHomepage,
          element: loader(() => import('../views/dashboard/HomepageView/CreateBannerHomepage'),),
        },
        {
          path: PATHS.dashboard.homepage.detailBannerHomepage,
          element: loader(() => import('../views/dashboard/HomepageView/DetailBannerHomepage'),),
        },
        {
          path: PATHS.dashboard.homepage.root,
          element: loader(() => import('../views/dashboard/HomepageView')),
        },
        {
          path: PATHS.dashboard.articles.root,
          element: loader(() => import('../views/dashboard/Articles')),
        },
        {
          path: PATHS.dashboard.articles.createArticles,
          element: loader(() => import('../views/dashboard/Articles/CreateArticle'),),
        },
        {
          path: PATHS.dashboard.advertiseWithUs.root,
          element: loader(() => import('../views/dashboard/AdvertiseWithUs')),
        },
        {
          path: PATHS.dashboard.adsPlacement.defaultAds,
          element: loader(() => import('../views/dashboard/AdsPlacement/DefaultAds'),),
        },
        {
          path: `${PATHS.dashboard.adsPlacement.root}`,
          children: [
            {
              path: `${PATHS.dashboard.adsPlacement.homepageDesktop}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.homepageMobile}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.articleCategoryDesktop}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.articleCategoryMobile}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.articleDetailDesktop}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.articleDetailMobile}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.videoDesktop}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.videoMobile}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.podcastDesktop}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.podcastMobile}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.videoPlaylistDetailDesktop}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.videoPlaylistDetailMobile}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.topicsDesktop}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.topicsMobile}`,
              element: loader(() => import('../views/dashboard/AdsPlacement/AdsPosition'),),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.adsSummary}`,
              element: loader(() => import('../views/dashboard/AdsSummary')),
            },
            {
              path: `${PATHS.dashboard.adsPlacement.adsSummaryDetail}`,
              element: loader(() => import('../views/dashboard/AdsSummary/AdsSummaryDetail'),),
            },
          ],
        },
        {
          path: PATHS.dashboard.video.root,
          element: loader(() => import('../views/dashboard/Video')),
        },
        {
          path: PATHS.dashboard.video.playlistItem,
          element: loader(() => import('../views/dashboard/Video/PlaylistItem'),),
        },
        {
          path: PATHS.dashboard.newVideos.root,
          element: loader(() => import('../views/dashboard/NewVideos')),
        },
        {
          path: PATHS.dashboard.podcast.root,
          element: loader(() => import('../views/dashboard/Podcast')),
        },
        {
          path: PATHS.dashboard.user.root,
          element: loader(() => import('../views/dashboard/User')),
        },
        {
          path: PATHS.dashboard.client.root,
          element: loader(() => import('../views/dashboard/Client')),
        },
        {
          path: PATHS.dashboard.analytic.clients,
          element: loader(() => import('../views/dashboard/Analytic/ClientList'),),
        },
        {
          path: PATHS.dashboard.analytic.ads,
          element: loader(() => import('../views/dashboard/Analytic/AdsAnalytic'),),
        },
        {
          path: PATHS.dashboard.analytic.report,
          element: loader(() => import('../views/dashboard/AdsReportOverview')),
        },
        {
          path: PATHS.dashboard.analytic.articles,
          element: loader(() => import('../views/dashboard/Analytic/ArticleAnalytic'),),
        },
        {
          path: PATHS.dashboard.disruptors.root,
          element: loader(() => import('../views/dashboard/Disruptors/index')),
        },
        {
          path: PATHS.dashboard.disruptors.createDisruptors,
          element: loader(() => import('../views/dashboard/Disruptors/CreateChapter'),),
        },
        {
          path: PATHS.dashboard.disruptors.detail,
          element: loader(() => import('../views/dashboard/Disruptors/DetailDisruptor'),),
        },
        {
          path: PATHS.dashboard.notification.root,
          element: loader(() => import('../views/dashboard/Notification/index'),),
        },
        {
          path: PATHS.dashboard.community.root,
          element: loader(() => import('../views/dashboard/Community/index'),),
        },
      ],
    },
    // eslint-disable-next-line react/react-in-jsx-scope
    { path: '*', element: <Navigate to={PATHS.notFound} replace /> },
  ]);
}

RequireAuth.propTypes = {
  children: PropTypes.element,
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
};
