import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation, Navigate, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box, Stack, Drawer, Button
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { logout } from 'src/redux/slices/auth';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection'; //
import sidebarConfig from './SidebarConfig';
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  flexShrink: 0,
}));

// ----------------------------------------------------------------------

function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    return <Navigate to="/" />;
  };
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = () => (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <NavSection navConfig={sidebarConfig} sidebarOpen={sidebarOpen} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: sidebarOpen ? 280 : 100,
          transition: '0.5s',
        },
      }}
    >
      <Box>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: sidebarOpen ? 280 : 100,
              backgroundColor: '#11172B',
              padding: '40px 0',
              display: 'flex',
              transition: '0.5s',
            },
          }}
        >
          <Box
            sx={{
              color: '#fff',
              padding: '0 25px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <KeyboardDoubleArrowLeftIcon
              sx={{
                cursor: 'pointer',
                transform: !sidebarOpen && 'rotate(180deg)',
                transition: '0.5s',
              }}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            />
          </Box>
          {sidebarOpen && (
            <Link to="/dashboard">
              <Stack
                spacing={3}
                sx={{
                  px: 2.5,
                  pt: 3,
                  pb: 2,
                }}
              >
                <Logo />
              </Stack>
            </Link>
          )}
          {renderContent()}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {sidebarOpen ? (
              <Button
                variant="outlined"
                sx={{ padding: '12px 48px', borderRadius: '18px' }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            ) : (
              <CancelPresentationTwoToneIcon
                sx={{ color: '#A4A6B3', cursor: 'pointer' }}
                onClick={handleLogout}
              />
            )}
          </Box>
        </Drawer>
      </Box>
    </RootStyle>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default DashboardSidebar;
