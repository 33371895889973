import React from 'react';
import PropTypes from 'prop-types';
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import breakpoints from './breakpoints';
import typography from './typography';
import componentsOverride from './overrides';
import palette from './palette';

function ThemeConfig({ children }) {
  const theme = createTheme({
    typography,
    breakpoints,
    palette,
  });
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export default ThemeConfig;
