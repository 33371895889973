import { CircularProgress, Box } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const LoadingScreen = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CircularProgress size={70} />
    </Box>
  );
};

export default LoadingScreen;
