/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  List,
  ListItemText,
  ListItemButton,
  Typography,
} from '@mui/material';
import { ChevronRight, } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import CircleIcon from '@mui/icons-material/Circle';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  subheader: {
    color: '#A4A6B3',
    '& p': {
      fontSize: '14px',
      fontWeight: '500',
      marginLeft: '8px',
    },
    cursor: 'pointer',
    padding: '12px',
    borderRadius: '6px',
    '&:hover': {
      background: 'rgba(124, 132, 163, 0.25)',
      color: '#fff',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subheaderExpandLess: {
    color: '#A4A6B3',
    cursor: 'pointer',
    padding: '12px',
    borderRadius: '6px',
    '&:hover': {
      background: 'rgba(124, 132, 163, 0.25)',
      color: '#fff',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  padding: '12px 10px 12px 20px',
  borderRadius: '6px',
  '&:hover': {
    background: 'rgba(124, 132, 163, 0.25)',
  },
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: '#DDE2FF',
  },
}));

// ----------------------------------------------------------------------
function NavItem({
  item, isShow, handleExpand, expand,
}) {
  const { pathname } = useLocation();
  const { title, path, info } = item;
  const isActiveRoot = path
    ? !!matchPath({ path, end: false }, pathname)
    : false;

  const isActiveRootNested = path
    ? !!matchPath({ path: `${path}/${title}`, end: false }, pathname)
    : false;

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: '#163967',
  };

  if (item.subItems) {
    return (
      <ListItemStyle
        sx={{
          ...(isActiveRootNested && activeRootStyle),
          margin: '5px 0',
        }}
        onClick={handleExpand}
      >
        {isShow && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <ListItemText
              disableTypography
              sx={{ color: '#fff', display: 'flex', alignItems: 'center' }}
            >
              <Box sx={{ width: '20px', mr: '5px' }}>
                <CircleIcon
                  sx={{
                    fontSize: isActiveRootNested ? '10px' : '7px',
                    transition: '0.5s',
                  }}
                />
              </Box>
              <Typography sx={{ fontSize: '14px' }}>{title}</Typography>
            </ListItemText>
            {info && info}
            <ChevronRight
              sx={{
                color: '#fff',
                fontSize: '18px',
                transform: expand ? 'rotate(90deg)' : 'none',
                transition: '0.5s',
              }}
            />
          </Box>
        )}
      </ListItemStyle>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
        margin: '5px 0',
      }}
    >
      {isShow && (
        <>
          <ListItemText
            disableTypography
            sx={{ color: '#fff', display: 'flex', alignItems: 'center' }}
          >
            <Box sx={{ width: '20px', mr: '5px', }}>
              <CircleIcon sx={{ fontSize: isActiveRoot ? '10px' : '7px', transition: '0.5s' }} />
            </Box>

            <Typography sx={{ fontSize: '14px' }}>{title}</Typography>
          </ListItemText>
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

NavItem.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array,
  expand: PropTypes.bool,
  setExpand: PropTypes.func,
};

const NavBox = ({ item, isShow }) => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/');
  const path = splitPathname[3];
  const [expand, setExpand] = useState(
    path === item.title.toLowerCase().replace(/ /g, '-'),
  );

  return (
    <>
      <NavItem
        key={item.title}
        item={item}
        isShow={isShow}
        handleExpand={() => setExpand(!expand)}
        expand={expand}
        nested={!!item?.subItems}
      />
      {expand && (
        <Box>
          {item?.subItems?.map((subItem) => (
            <Box
              key={subItem.path}
              component={RouterLink}
              to={subItem.path}
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#fff',
                marginBottom: '5px',
                padding: '10px 0 10px 40px',
                backgroundColor: matchPath(
                  { path: subItem?.path, end: false },
                  pathname,
                )
                  && 'rgba(124, 132, 163, 0.25)',

                borderRadius: '6px',
                '&:hover': {
                  background: 'rgba(124, 132, 163, 0.25)',
                },
              }}
            >
              <CircleIcon sx={{ fontSize: '7px', marginRight: '16px' }} />
              <Typography sx={{ fontSize: '14px' }}>{subItem.title}</Typography>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

NavBox.propTypes = {
  isShow: PropTypes.bool,
  item: PropTypes.object,
};

const NavItemExpand = ({
  subheader, isShow, items, icon
}) => {
  const [expand, setExpand] = useState(false);
  const classes = useStyles();
  return (
    <>
      <Box
        onClick={() => setExpand(!expand)}
        className={classes.subheader}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {icon}
          <Typography>{subheader}</Typography>
        </Box>
        <ChevronRight
          sx={{
            color: '#fff',
            fontSize: '18px',
            transform: expand ? 'rotate(90deg)' : 'none',
            transition: '0.5s',
          }}
        />
      </Box>
      <Box sx={{ display: expand ? 'block' : 'none' }}>
        {items?.map((item) => (
          <NavBox item={item} isShow={isShow} key={item.title} />
        ))}
      </Box>
    </>
  );
};

function NavSection({
  navConfig, isShow = true, sidebarOpen, ...other
}) {
  const classes = useStyles();
  return (
    <Box sx={{ marginTop: sidebarOpen ? '30px' : '100px', padding: '0 20px' }} {...other}>
      {navConfig.map((list) => {
        const {
          subheader, items, path, icon
        } = list;
        return (
          <List sx={{ marginBottom: '10px' }} key={subheader} disablePadding>
            {sidebarOpen ? (
              path ? (
                <Box className={classes.subheader}>
                  <RouterLink to={path}>{subheader}</RouterLink>
                </Box>
              ) : (
                <NavItemExpand
                  subheader={subheader}
                  items={items}
                  isShow={isShow}
                  icon={icon}
                />
              )
            ) : (
              <Box className={classes.subheaderExpandLess}>
                {icon}
              </Box>
            )}
          </List>
        );
      })}
    </Box>
  );
}

NavSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array,
};

NavItem.propTypes = {
  isShow: PropTypes.bool,
  item: PropTypes.object,
  handleExpand: PropTypes.func,
  expand: PropTypes.bool,
  nested: PropTypes.bool,
};

export default NavSection;
