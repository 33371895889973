import {
  ApolloClient, ApolloLink, from, InMemoryCache
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { appConfig } from '../config';

const middleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      token: localStorage.getItem('token'),
    },
  }));

  return forward(operation);
});

const apolloClient = new ApolloClient({
  link: from([
    middleware,
    createUploadLink({
      uri: appConfig.baseUrl,
    }),
  ]),
  cache: new InMemoryCache(),
});

export default apolloClient;
