/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const setSession = (token) => {
  if (token) {
    localStorage.setItem('token', token);
  } else {
    localStorage.removeItem('token');
  }
};

// ----------------------------------------------------------------------

export function signin(user, token) {
  return (dispatch) => {
    setSession(token);
    dispatch(
      slice.actions.loginSuccess({
        user,
      }),
    );
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    try {
      const firebaseAuth = firebase.auth();
      await firebaseAuth.signOut();
      setSession(null);
      dispatch(slice.actions.logoutSuccess());
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (!localStorage.getItem('token')) {
        throw new Error('token invalid');
      }
      const firebaseAuth = firebase.auth();
      firebaseAuth.onAuthStateChanged((user) => {
        if (user) {
          const { providerId } = user.providerData[0];
          if (providerId === 'password' && !user.emailVerified) {
            dispatch(
              slice.actions.getInitialize({
                isAuthenticated: false,
                user: null,
              }),
            );
          } else {
            const data = {
              uid: user.uid,
              email: user.email,
              isVerified: user.emailVerified,
              displayName: user.displayName,
              phoneNumber: user.phoneNumber,
              photoURL: user.photoURL,
            };

            dispatch(
              slice.actions.getInitialize({
                isAuthenticated: true,
                user: data,
              }),
            );
          }
        } else {
          dispatch(
            slice.actions.getInitialize({
              isAuthenticated: false,
              user: null,
            }),
          );
        }
      });
    } catch (error) {
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null,
        }),
      );
    }
  };
}
