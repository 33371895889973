import firebase from 'firebase/app';
import 'firebase/auth';
import { firebaseConfig } from 'src/config';
import PropTypes from 'prop-types';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const FirebaseProvider = ({ children }) => children;

FirebaseProvider.propTypes = {
  children: PropTypes.element,
};

export default FirebaseProvider;
