import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { store, persistor } from 'src/redux/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import apolloClient from './lib/apolloClient';
import ThemeConfig from './theme/core';
import GlobalStyles from './theme/core/globalStyles';
import ScrollToTop from './components/ScrollToTop';
import Router from './routes';
import FirebaseProvider from './components/provider/FirebaseProvider';
import LoadingScreen from './components/LoadingScreen';
import AuthProvider from './components/provider/AuthProvider';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <ThemeConfig>
          <FirebaseProvider>
            <ApolloProvider client={apolloClient}>
              <GlobalStyles />
              <ScrollToTop />
              <SnackbarProvider
                autoHideDuration={3000}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <AuthProvider>
                  <Router />
                </AuthProvider>
              </SnackbarProvider>
            </ApolloProvider>
          </FirebaseProvider>
        </ThemeConfig>
      </PersistGate>
    </Provider>
  );
}

export default App;
