import { useDispatch, useSelector } from 'react-redux';
import { signin, logout } from 'src/redux/slices/auth';

// ----------------------------------------------------------------------

export default function useAuth() {
  // JWT Auth
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state) => state.auth,
  );

  return {
    method: 'jwt',
    user,
    isLoading,
    isAuthenticated,

    login: ({ email, password }) => dispatch(
      signin({
        email,
        password,
      }),
    ),

    logout: () => dispatch(logout()),

    resetPassword: () => {},

    updateProfile: () => {},
  };
}
