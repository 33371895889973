// routes
// components icon
import {
  PodcastsOutlined, Person, DashboardOutlined,
  ArticleOutlined, AnalyticsOutlined,
  OndemandVideoOutlined, AccountBoxOutlined,
  AddIcCallOutlined,
  Assessment,
  NotificationAdd,
  Forum
} from '@mui/icons-material';
import React from 'react';
import { PATHS } from '../../routes/paths';

// ----------------------------------------------------------------------

const sidebarConfig = [
  // OVERVIEW
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    icon: <DashboardOutlined />,
    items: [
      {
        title: 'Homepage',
        path: PATHS.dashboard.homepage.root,
      },
      {
        title: 'Advertise With Us',
        path: PATHS.dashboard.advertiseWithUs.root,
      },
      {
        title: 'Disruptors',
        path: PATHS.dashboard.disruptors.root,
      },
    ],
  },

  // ARTICLE
  // ----------------------------------------------------------------------
  {
    subheader: 'Article',
    icon: <ArticleOutlined />,
    items: [
      {
        title: 'Article List',
        path: PATHS.dashboard.articles.root,
      },
    ],
  },
  // ADS PLACEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Ads Placement',
    icon: <AnalyticsOutlined />,
    items: [
      {
        title: 'Homepage',
        path: `${PATHS.dashboard.adsPlacement.root}`,
        subItems: [
          {
            title: 'Desktop',
            path: `${PATHS.dashboard.adsPlacement.homepageDesktop}`,
          },
          {
            title: 'Mobile',
            path: `${PATHS.dashboard.adsPlacement.homepageMobile}`,
          },
        ],
      },
      {
        title: 'Article Category',
        path: `${PATHS.dashboard.adsPlacement.root}`,
        subItems: [
          {
            title: 'Desktop',
            path: `${PATHS.dashboard.adsPlacement.articleCategoryDesktop}`,
          },
          {
            title: 'Mobile',
            path: `${PATHS.dashboard.adsPlacement.articleCategoryMobile}`,
          },
        ],
      },
      {
        title: 'Article Detail',
        path: `${PATHS.dashboard.adsPlacement.root}`,
        subItems: [
          {
            title: 'Desktop',
            path: `${PATHS.dashboard.adsPlacement.articleDetailDesktop}`,
          },
          {
            title: 'Mobile',
            path: `${PATHS.dashboard.adsPlacement.articleDetailMobile}`,
          },
        ],
      },
      {
        title: 'Video',
        path: `${PATHS.dashboard.adsPlacement.root}`,
        subItems: [
          {
            title: 'Desktop',
            path: `${PATHS.dashboard.adsPlacement.videoDesktop}`,
          },
          {
            title: 'Mobile',
            path: `${PATHS.dashboard.adsPlacement.videoMobile}`,
          },
        ],
      },
      {
        title: 'Video Playlist Detail',
        path: `${PATHS.dashboard.adsPlacement.root}`,
        subItems: [
          {
            title: 'Desktop',
            path: `${PATHS.dashboard.adsPlacement.videoPlaylistDetailDesktop}`,
          },
          {
            title: 'Mobile',
            path: `${PATHS.dashboard.adsPlacement.videoPlaylistDetailMobile}`,
          },
        ],
      },
      {
        title: 'Podcast',
        path: `${PATHS.dashboard.adsPlacement.root}`,
        subItems: [
          {
            title: 'Desktop',
            path: `${PATHS.dashboard.adsPlacement.podcastDesktop}`,
          },
          {
            title: 'Mobile',
            path: `${PATHS.dashboard.adsPlacement.podcastMobile}`,
          },
        ],
      },
      {
        title: 'Topics',
        path: `${PATHS.dashboard.adsPlacement.root}`,
        subItems: [
          {
            title: 'Desktop',
            path: `${PATHS.dashboard.adsPlacement.topicsDesktop}`,
          },
          {
            title: 'Mobile',
            path: `${PATHS.dashboard.adsPlacement.topicsMobile}`,
          },
        ],
      },
      {
        title: 'Summary Ads',
        path: `${PATHS.dashboard.adsPlacement.adsSummary}`,
      },
    ],
  },

  // PODCAST
  // ----------------------------------------------------------------------
  {
    subheader: 'Podcast',
    icon: <PodcastsOutlined />,
    items: [
      {
        title: 'Podcast List',
        path: PATHS.dashboard.podcast.root,
      },
    ],
  },
  // VIDEO
  // ----------------------------------------------------------------------
  {
    subheader: 'Video',
    icon: <OndemandVideoOutlined />,
    items: [
      {
        title: 'Playlists',
        path: PATHS.dashboard.video.root,
      },
      {
        title: 'New Videos',
        path: PATHS.dashboard.newVideos.root,
      },
    ],
  },
  // USER
  // ----------------------------------------------------------------------
  {
    subheader: 'User Management',
    icon: <AccountBoxOutlined />,
    items: [
      {
        title: 'Admin',
        path: PATHS.dashboard.user.root,
        icon: <Person />,
      },
    ],
  },
  // CLIENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Our Clients',
    icon: <AddIcCallOutlined />,
    items: [
      {
        title: 'List',
        path: PATHS.dashboard.client.root,
      },
    ],
  },
  // ANALYTIC
  // ----------------------------------------------------------------------
  {
    subheader: 'Analytics',
    icon: <Assessment />,
    items: [
      {
        title: 'Ads Analytic',
        path: PATHS.dashboard.analytic.clients,
      },
      {
        title: 'Article Analytic',
        path: PATHS.dashboard.analytic.articles,
      },
    ],
  },
  // ANALYTIC
  // ----------------------------------------------------------------------
  // Notification
  {
    subheader: 'Notification',
    icon: <NotificationAdd />,
    items: [
      {
        title: 'Push Notification',
        path: PATHS.dashboard.notification.root,
      }
    ],
  },
  // Community
  // {
  //   subheader: 'Community',
  //   icon: <Forum />,
  //   items: [
  //     {
  //       title: 'Question List',
  //       path: PATHS.dashboard.community.root,
  //     }
  //   ],
  // },
];

export default sidebarConfig;
