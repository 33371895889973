import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

function Logo({ className, ...other }) {
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/brand/logo-finfolk.svg"
      height={30}
      className={className}
      {...other}
    />
  );
}

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
