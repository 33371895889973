import React, { Suspense, lazy } from 'react';

import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const loader = (component) => {
  const Component = Loadable(lazy(component));

  return <Component />;
};

export default loader;
