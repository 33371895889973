/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import React from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  flexGrow: 1,
  height: '100%',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },

  /* Track */
  '&::-webkit-scrollbar-track': {
    display: 'none',
  },

  /* Handle */
  '&::-webkit-scrollbar-thumb': {
    display: 'none',
  },

  /* Handle on hover */
  '&::-webkit-scrollbar-thumb:hover': {
    display: 'none',
  },
});

// ----------------------------------------------------------------------

function Scrollbar({ children, sx, ...other }) {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return <RootStyle>{children}</RootStyle>;
}

Scrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default Scrollbar;
