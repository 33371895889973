// ----------------------------------------------------------------------

export default function Input() {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {},
      },
    },
  };
}
