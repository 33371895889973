const DARK = {
  0: '#202b5b',
  10: '#1d2752',
  20: '#1a2249',
  30: '#161e40',
  40: '#131a37',
  50: '#10162e',
  60: '#0d1124',
  70: '#0a0d1b',
  80: '#060912',
  90: '#030409',
  100: '#000000',
};

const LIGHT = {
  0: '#202b5b',
  10: '#36406b',
  20: '#4d557c',
  30: '#636b8c',
  40: '#79809d',
  50: '#9095ad',
  60: '#a6aabd',
  70: '#bcbfce',
  80: '#d2d5de',
  90: '#e9eaef',
  100: '#ffffff',
};

const palette = {
  DARK,
  LIGHT,
};

export default palette;
