// ----------------------------------------------------------------------

export default function Button() {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
          textTransform: 'capitalize',
        },
        sizeLarge: {
          height: 48,
        },
        // contained
        containedPrimary: {
          backgroundColor: '#0052CC',
          '&:hover': {
            backgroundColor: '#bcbfce',
          },
        },
      },
    },
  };
}
