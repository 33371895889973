import { Box, Typography, Container } from '@mui/material';
import React from 'react';

const ForceMobileDevicePage = () => (
  <Box sx={{ background: 'rgba(124, 132, 163, 0.16)', height: '100vh' }}>
    <Container sx={{
      display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    }}
    >
      <Box sx={{
        width: '80%'
      }}
      >
        <img src="/static/image/desktop.svg" alt="banner" />
      </Box>
      <Typography sx={{ color: '#222', mt: 3, fontWeight: '600' }}>Only open in Desktop</Typography>
    </Container>
  </Box>
);

export default ForceMobileDevicePage;
