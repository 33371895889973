/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */

import { gql } from '@apollo/client';
import firebase from 'firebase/app';
import apolloClient from 'src/lib/apolloClient';

const firebaseAuth = firebase.auth();

const loginWithEmailAndPassword = async ({ email, password }) => {
  if (!email.includes('@finfolk.co')) {
    return {
      error: 'Email tidak valid',
    };
  }

  try {
    const { user } = await firebaseAuth.signInWithEmailAndPassword(
      email,
      password,
    );

    if (!user.emailVerified) {
      await firebaseAuth.signOut();
      return {
        error: 'Email belum di verifikasi',
      };
    }
    return {
      user,
      isUserCompleteProfile: true,
    };
  } catch (error) {
    console.log(error);
    return {
      error: error.message,
    };
  }
};

const fetchIsAdmin = async ({ email }) => {
  try {
    const result = await apolloClient.query({
      query: gql`
        query User($email: String) {
          user(email: $email) {
            id
            isAdmin
            token
          }
        }
      `,
      variables: {
        email,
      },
    });
    return result?.data?.user || null;
  } catch (error) {
    console.log(error);
  }
};

const verifyToken = async () => {
  try {
    const token = localStorage.getItem('token');
    const result = await apolloClient.query({
      query: gql`
          query ValidateToken($token: String!) {
            validateToken(token: $token) {
              isValid
            }
          }
        `,
      variables: {
        token,
      }
    });
    return result?.data?.validateToken?.isValid;
  } catch (error) {
    console.log(error.message);
  }
};

export { loginWithEmailAndPassword, fetchIsAdmin, verifyToken };
