import React from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import ForceMobileDevicePage from 'src/components/ForceMobileDevicePage';
import useMediaQuery from '@mui/material/useMediaQuery';
import DashboardSidebar from './DashboardSidebar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: '#FFF',
  color: '#000',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    // paddingTop: 47,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const isMobile = useMediaQuery('(max-width:600px)');
  if (isMobile) {
    return (
      <ForceMobileDevicePage />
    );
  }
  return (
    <RootStyle>
      <DashboardSidebar />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
